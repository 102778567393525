import styled from "styled-components"
import { motion, MotionStyle } from "framer-motion"
import { useState, useEffect, useRef } from "react"
import { CaretDown, CaretUp } from "@phosphor-icons/react"

import ValueArrow from "https://framer.com/m/ValueArrow-s2Or.js@fBDFSeutnE7uyDVezOhT"

const configValueSets = {
    "Set 1": [
        {
            leftValues: ["Freedom", "Openness", "Generosity"],
            rightValues: ["Accountability", "Efficiency", "Decisiveness"],
        },
        {
            leftValues: ["Honesty", "Naivety", "Youth"],
            rightValues: ["Liberalness", "Maturity", "Experience"],
        },
        {
            leftValues: ["Creativity", "Exploration", "Play"],
            rightValues: ["Work", "Craft", "Perfection"],
        },
        {
            leftValues: ["Restraint", "Discipline", "Responsibility"],
            rightValues: ["Spontaneity", "Improvisation", "Instinct"],
        },
        {
            leftValues: ["Rigour", "Politeness", "Seriousness"],
            rightValues: ["Playfullness", "Humour", "Fun"],
        },
        {
            leftValues: ["Emotion", "Games", "Art"],
            rightValues: ["Science", "Design", "Rationality"],
        },
    ],
    "Set 2": [
        {
            leftValues: ["Honesty", "Naivety", "Youth"],
            rightValues: ["Liberalness", "Maturity", "Experience"],
        },
        {
            leftValues: ["Emotion", "Games", "Art"],
            rightValues: ["Science", "Design", "Rationality"],
        },
        {
            leftValues: ["Freedom", "Openness", "Generosity"],
            rightValues: ["Accountability", "Efficiency", "Decisiveness"],
        },
        {
            leftValues: ["Restraint", "Discipline", "Responsibility"],
            rightValues: ["Spontaneity", "Improvisation", "Instinct"],
        },
        {
            leftValues: ["Creativity", "Exploration", "Play"],
            rightValues: ["Work", "Craft", "Perfection"],
        },
        {
            leftValues: ["Rigour", "Politeness", "Seriousness"],
            rightValues: ["Playfullness", "Humour", "Fun"],
        },
    ],
    "Set 3": [
        {
            leftValues: ["Restraint", "Discipline", "Responsibility"],
            rightValues: ["Spontaneity", "Improvisation", "Instinct"],
        },
        {
            leftValues: ["Creativity", "Exploration", "Play"],
            rightValues: ["Work", "Craft", "Perfection"],
        },
        {
            leftValues: ["Freedom", "Openness", "Generosity"],
            rightValues: ["Accountability", "Efficiency", "Decisiveness"],
        },
        {
            leftValues: ["Emotion", "Games", "Art"],
            rightValues: ["Science", "Design", "Rationality"],
        },
        {
            leftValues: ["Rigour", "Politeness", "Seriousness"],
            rightValues: ["Playfullness", "Humour", "Fun"],
        },
        {
            leftValues: ["Honesty", "Naivety", "Youth"],
            rightValues: ["Liberalness", "Maturity", "Experience"],
        },
    ],
}

/**
 * @framerSupportedLayoutWidth auto
 * @framerSupportedLayoutHeight auto
 */
export default function ValueTable(props: {
    onChange: (v: string[][]) => void
    onLocked: (l: boolean) => void
}) {
    const { onChange, onLocked } = props
    const [selectedSet, setSelectedSet] = useState(
        Object.keys(configValueSets)[0]
    )
    const [locked, setLocked] = useState(false)
    const [result, setResult] = useState([])
    const containerRef = useRef(null)
    const [containerWidth, setContainerWidth] = useState(0)

    useEffect(() => {
        if (!containerRef.current) {
            return
        }
        const observer = new ResizeObserver((e) => {
            setContainerWidth(e[0].contentRect.width)
        })
        observer.observe(containerRef.current)
        return () => {
            observer.disconnect()
        }
    }, [containerRef])

    useEffect(() => {
        onLocked(locked)
    }, [locked])

    return (
        <motion.div ref={containerRef}>
            <motion.div style={{ textAlign: "center" }}>
                {Object.keys(configValueSets).map((vset) => (
                    <VsetButton
                        key={vset}
                        disabled={vset == selectedSet}
                        style={
                            vset == selectedSet
                                ? {
                                      ...vsetButtonStyle,
                                      ...selectedVsetButtonStyle,
                                  }
                                : vsetButtonStyle
                        }
                        onClick={() => {
                            if (vset != selectedSet) {
                                setSelectedSet(vset)
                                setLocked(false)
                            }
                        }}
                    >
                        {vset}
                    </VsetButton>
                ))}
            </motion.div>
            <ValueGrid
                locked={locked}
                valueSetConfig={configValueSets[selectedSet]}
                onChange={(v: string[][]) => {
                    onChange(v)
                    setResult(v)
                }}
                containerWidth={containerWidth}
            />
            <motion.div style={{ textAlign: "center", margin: "20px" }}>
                <VsetButton
                    onClick={() => {
                        setLocked(!locked)
                    }}
                    style={vsetButtonStyle}
                >
                    {locked ? "Reset" : "Proceed"}
                </VsetButton>
            </motion.div>
        </motion.div>
    )
}

ValueTable.defaultProps = {
    onChange: (v: string[][]) => {},
    onLocked: (v: boolean) => {},
}

function ValueGrid(props: {
    valueSetConfig: object
    onChange: (v: string[][]) => void
    locked: boolean
    containerWidth: number
}) {
    const { valueSetConfig, locked, onChange, containerWidth } = props
    const [valueSet, setValueSet] = useState(new ValueSet(valueSetConfig))

    useEffect(() => {
        onChange(valueSet.values())
    }, [valueSet])

    useEffect(() => {
        setValueSet(new ValueSet(valueSetConfig))
    }, [valueSetConfig])

    return (
        <>
            <motion.div
                style={{ ...gridStyle, opacity: locked ? "50%" : "100%" }}
            >
                {valueSet.pairs().map((pair, idx) => (
                    <ValueRow
                        key={idx}
                        valuePair={pair}
                        setValuePair={(vp: ValuePair) => {
                            setValueSet(valueSet.setPair(idx, vp))
                        }}
                        locked={locked}
                        containerWidth={containerWidth}
                    />
                ))}
            </motion.div>
        </>
    )
}

function ValueRow(props: {
    valuePair: ValuePair
    setValuePair: (ValuePair) => void
    locked: boolean
    containerWidth: number
}) {
    const { valuePair, setValuePair, locked, containerWidth } = props

    return (
        <>
            <ValueSide
                leftSide={true}
                values={valuePair.leftValues}
                selected={valuePair.leftSelected}
                setSelected={(v) => {
                    setValuePair(valuePair.setLeftSelected(v))
                }}
                locked={locked}
                containerWidth={containerWidth}
            />

            {containerWidth > 600 ? (
                <motion.div style={centerColumnStyle}>
                    <ValueArrow />
                </motion.div>
            ) : (
                <motion.div
                    style={{
                        ...centerColumnStyle,
                        maxWidth: "50px",
                        overflow: "hidden",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <ValueArrow style={{ margin: "0 -100%" }} />
                </motion.div>
            )}

            <ValueSide
                leftSide={false}
                values={valuePair.rightValues}
                selected={valuePair.rightSelected}
                setSelected={(v) => {
                    setValuePair(valuePair.setRightSelected(v))
                }}
                locked={locked}
                containerWidth={containerWidth}
            />
        </>
    )
}

function ValueSide(props: {
    leftSide: boolean
    values: string[]
    selected: string
    setSelected: (string) => void
    locked: boolean
    containerWidth: number
}) {
    const { leftSide, values, selected, setSelected, locked, containerWidth } =
        props
    const [fullWidth, setFullWidth] = useState(true)

    useEffect(() => {
        setFullWidth(containerWidth > 1000)
    }, [containerWidth])

    return (
        <motion.div style={leftSide ? leftColumnStyle : rightColumnStyle}>
            <motion.div style={baseCellStyle}>
                {fullWidth ? (
                    values.map((value) => (
                        <ItemButton
                            key={value}
                            disabled={locked || value == selected}
                            style={
                                value == selected
                                    ? { ...itemStyle, ...selectedItemStyle }
                                    : locked
                                      ? { ...itemStyle, cursor: "default" }
                                      : itemStyle
                            }
                            onClick={() => {
                                if (value != selected) {
                                    setSelected(value)
                                }
                            }}
                        >
                            {value}
                        </ItemButton>
                    ))
                ) : (
                    <ValueSideDropdown
                        leftSide={leftSide}
                        values={values}
                        selected={selected}
                        setSelected={setSelected}
                        locked={locked}
                    />
                )}
            </motion.div>
        </motion.div>
    )
}

function ValueSideDropdown(props: {
    leftSide: boolean
    values: string[]
    selected: string
    setSelected: (string) => void
    locked: boolean
}) {
    const { leftSide, values, selected, setSelected, locked } = props
    const [selectedValue, setSelectedValue] = useState(values[0])
    const [expanded, setExpanded] = useState(false)
    const newRef = useRef(null)

    useEffect(() => {
        const closeHandler = (e) => {
            if (newRef.current && !newRef.current.contains(e.target)) {
                setExpanded(false)
            }
        }
        document.addEventListener("mousedown", closeHandler)
        return () => {
            document.removeEventListener("mousedown", closeHandler)
        }
    })

    useEffect(() => {
        for (const v of values) {
            if (v == selected) {
                setSelectedValue(v)
            }
        }
    }, [values, selected])

    const droppableContainerStyle: MotionStyle = {
        position: "absolute",
        top: "53px",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "white",
        borderRadius: "8px",
        zIndex: 20,
        boxShadow: "0px 6px 20px 0px rgba(0, 0, 0, 0.1)",
    }
    const IconUp = styled(CaretUp)`
    @media (min-width: 500px) {
        display: block
    }
    display: none
    `
    const IconDown = styled(CaretDown)`
    @media (min-width: 500px) {
        display: block
    }
    display: none
    `
    const SelectedDiv = styled(motion.div)`
    @media (min-width: 500px) {
        margin-right: 7px
    }
    margin-right: 0
    `

    return (
        <motion.div
            ref={newRef}
            style={{
                display: "flex",
                flexDirection: "column",
                position: "relative",
            }}
        >
            <ItemButton
                style={{
                    ...itemStyle,
                    ...selectedItemStyle,
                    cursor: "pointer",
                    display: "flex",
                }}
                disabled={locked}
                onClick={() => {
                    setExpanded(!expanded)
                }}
            >
                <SelectedDiv>{selectedValue}</SelectedDiv>
                {expanded ? <IconUp /> : <IconDown />}
            </ItemButton>
            {expanded ? (
                <motion.div style={droppableContainerStyle}>
                    {values
                        .filter((v) => v != selected)
                        .map((v) => (
                            <ItemButton
                                style={{ ...itemStyle, borderRadius: "8px" }}
                                onClick={() => {
                                    setSelected(v)
                                    setExpanded(false)
                                }}
                            >
                                {v}
                            </ItemButton>
                        ))}
                </motion.div>
            ) : null}
        </motion.div>
    )
}

class ValueSet {
    valuePairs: ValuePair[]

    constructor(config) {
        this.valuePairs = []
        for (const pairConfig of config) {
            this.valuePairs.push(new ValuePair(pairConfig))
        }
    }
    pairs(): ValuePair[] {
        return this.valuePairs
    }
    setPair(idx: number, pair: ValuePair): ValueSet {
        this.valuePairs[idx] = pair
        // return a new copy of ValueSet to trigger React state update.
        return Object.assign(Object.create(this), this)
    }
    values(): string[][] {
        let result = []
        for (const pair of this.valuePairs) {
            result.push(pair.values())
        }
        return result
    }
}

class ValuePair {
    leftValues: string[]
    rightValues: string[]
    leftSelected: string
    rightSelected: string

    constructor(pairConfig) {
        this.leftValues = pairConfig["leftValues"]
        this.rightValues = pairConfig["rightValues"]
        this.leftSelected = pairConfig["leftValues"].at(-1)
        this.rightSelected = pairConfig["rightValues"][0]
    }

    setLeftSelected(value: string): ValuePair {
        this.leftSelected = value
        // return a new copy of ValuePair to trigger React state update.
        return Object.assign(Object.create(this), this)
    }
    setRightSelected(value: string): ValuePair {
        this.rightSelected = value
        // return a new copy of ValuePair to trigger React state update.
        return Object.assign(Object.create(this), this)
    }
    values(): string[] {
        return [this.leftSelected, this.rightSelected]
    }
}

const VsetButton = styled(motion.button)`
    @media (min-width: 1000px) {
        font-size: 20px
    }
    @media (min-width: 800px) {
        font-size: 16px
    }
    font-size: 13px
`

const vsetButtonStyle: MotionStyle = {
    display: "inline-flex",
    padding: "12px 24px 12px 24px",
    backgroundColor: "#ffffff",
    borderRadius: "16px",
    border: 0,
    margin: "2px 7px 2px 7px",

    fontWeight: 500,
    fontFamily: "DM Mono, monospace",
    color: "black",
    lineHeight: "130%",

    cursor: "pointer",
}

const selectedVsetButtonStyle: MotionStyle = {
    cursor: "default",
    backgroundColor: "#353a77",
    color: "#f2eee2",
}

const gridStyle: MotionStyle = {
    marginTop: "55px",
    display: "grid",
    gridTemplateColumns: "[left] 1fr [arrow] max-content [right] 1fr",
    rowGap: "24px",
    columnGap: "23px",
}

const leftColumnStyle: MotionStyle = {
    gridColumn: 1,
    justifySelf: "end",
}

const centerColumnStyle: MotionStyle = {
    gridColumn: 2,
    placeSelf: "center",
}

const rightColumnStyle: MotionStyle = {
    gridColumn: 3,
}

const baseCellStyle: MotionStyle = {
    display: "flex",
    float: "left",
    backgroundColor: "white",
    borderRadius: "16px",
}

const ItemButton = styled(motion.button)`
    @media (min-width: 1000px) {
        font-size: 16px
    }
    @media (min-width: 800px) {
        font-size: 14px
    }
    font-size: 12px
`

const itemStyle: MotionStyle = {
    fontFamily: "Faktum Medium",
    color: "black",
    lineHeight: "1.5em",

    justifyContent: "center",
    alignItems: "center",
    padding: "12px 24px 12px 24px",
    alignContent: "center",
    gap: "10",
    cursor: "pointer",
    backgroundColor: "white",
    borderRadius: "16px",

    border: 0,
}

const selectedItemStyle: MotionStyle = {
    backgroundColor: "#004751",
    color: "white",
    cursor: "default",
}
